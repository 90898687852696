import { getDefaultProduct } from "../utils/functions/getDefaultProduct"
import {
  normalizeAmount,
  formatMoneyAmount,
  formatCartTotal,
} from "../utils/prices"

const BRANCH = process.env.BRANCH || "master"

export function trackCartViewed(cart) {
  if (window.dataLayer) {
    const data = {
      currency: cart?.region?.currency_code?.toUpperCase(),
      value: normalizeAmount(cart?.region?.currency_code, cart.total),
      items: cart?.items.map((i) => {
        const skuParts = i.variant.sku?.split("-") || []
        skuParts.pop()
        const currency = cart?.region?.currency_code
        const price = normalizeAmount(currency, i.unit_price)
        return {
          item_id: i.variant.product_id,
          item_sku: skuParts.join("-"),
          item_name: i.title,
          item_brand: "Tekla",
          item_variant: i.variant.sku || "",
          price,
          currency: cart?.region?.currency_code?.toUpperCase(),
        }
      }),
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Cart Viewed (GTM)",
      email: cart.email,
      ecommerce: data,
    })
  }
}

export function trackSplitTestView(label) {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Split Test Viewed (GTM)",
      ecommerce: { label },
    })
  }
}

export function trackCheckoutInitialized(cart) {
  try {
    const currency = cart?.region?.currency_code
    const data = {
      value: normalizeAmount(
        currency,
        cart.total - cart.tax_total || 0 - cart.shipping_total || 0
      ),
      discount: normalizeAmount(currency, cart.discountTotal) || 0,
      tax: normalizeAmount(currency, cart.tax_total),
      coupon: cart.couponCode,
      order_id: cart?.id,
      currency: currency?.toUpperCase(),
      display_total: formatCartTotal(cart),
      items: cart?.items.map((item) => {
        const skuParts = item.variant.sku?.split("-") || []
        skuParts.pop()

        const price = normalizeAmount(currency, item.unit_price)
        const tax = normalizeAmount(currency, item.tax_total)
        const discount = normalizeAmount(currency, item.discount_total)

        let imgUrl = item.thumbnail || ""
        if (imgUrl.startsWith("//")) {
          imgUrl = `https:${imgUrl}`
        }

        return {
          item_id: item.variant.product_id,
          item_sku: skuParts.join("-"),
          item_name: item.title,
          item_variant: item.variant.sku,
          item_category: item.category,
          display_price: formatMoneyAmount(
            {
              currencyCode: cart?.region?.currency_code,
              amount: item.original_total,
            },
            0
          ),
          item_color: item.description,
          price,
          quantity: item.quantity,
          image_url: imgUrl,
          tax,
          discount,
        }
      }),
    }

    if (window.dataLayer) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "Checkout Started (GTM)",
        email: cart.email,
        ecommerce: data,
      })
    }
  } catch (err) {
    console.log(err)
  }
}

export function trackCheckoutStepViewed(cart, step, stepLabel = "") {
  let eventName = ""

  switch (step) {
    case 1:
      eventName = "begin_checkout"
      break
    case 2:
      eventName = "add_shipping_info"
      break
    case 3:
      eventName = "add_payment_info"
      break
    default:
      break
  }

  if (window && window.dataLayer && cart) {
    const data = {
      checkout_id: cart?.id,
      step,
      shipping_method: cart.shippingMethod && cart.shippingMethod.name,
      payment_method: cart.paymentMethod && cart.paymentMethod.pm_identifier,
      items: cart?.items?.map((item) => {
        const skuParts = item.variant.sku?.split("-") || []
        skuParts.pop()

        const price = normalizeAmount(
          cart?.region?.currency_code,
          item.unit_price
        )

        const tax = normalizeAmount(cart?.region?.currency_code, item.tax_total)
        const discount = normalizeAmount(cart?.region?.currency_code, item.discount_total)

        let imgUrl = item.thumbnail || ""
        if (imgUrl.startsWith("//")) {
          imgUrl = `https:${imgUrl}`
        }

        return {
          item_id: item.variant.product_id,
          item_sku: skuParts.join("-"),
          item_name: item.title,
          item_variant: item.variant.sku,
          display_price: formatMoneyAmount(
            {
              currencyCode: cart?.region?.currency_code,
              amount: item.original_total,
            },
            0
          ),
          item_color: item.description,
          price,
          quantity: item.quantity,
          image_url: imgUrl,
          tax,
          discount
        }
      }),
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: eventName,
      ecommerce: data,
      email: cart.email,
      label: stepLabel,
    })
  }
}

export function trackCheckoutStepCompleted(cart, step) {
  let eventName = ""

  switch (step) {
    case 1:
      eventName = "begin_checkout"
      break
    case 2:
      eventName = "add_shipping_info"
      break
    case 3:
      eventName = "add_payment_info"
      break
    default:
      break
  }

  if (window && window.dataLayer && cart) {
    const data = {
      checkout_id: cart?.id,
      step,
      shipping_method: cart.shippingMehtod && cart.shippingMethod.name,
      payment_method: cart.paymentMethod && cart.paymentMethod.pm_identifier,
      address_2: cart.shipping_address.address_2,
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Checkout Step Completed (GTM)",
      ecommerce: data,
    })
  }
}

export function trackPaymentEntered(cart, step) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Payment Info Entered (GTM)",
      ecommerce: {
        checkout_id: cart?.id,
        cart_id: cart?.id,
      },
    })
  }
}

export function trackProductListViewed(collectionName, products) {
  if (window && window.dataLayer) {
    const data = {
      list_id: collectionName,
      category: collectionName,
      items: products.map(
        (p, index) => {
          if ("duvetProduct" in p) {
            p = getDefaultProduct(p)
          }
          return {
            item_id: p.objectId,
            item_sku: p.sku,
            item_name: p.title,
            item_category: collectionName,
            position: index + 1,
          }
        },
        {
          // integrations: handleIntegrations(),
        }
      ),
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Product list Viewed (GTM)",
      ecommerce: data,
    })
  }
}

export function trackProductViewed(product, collection) {
  if (window && window.dataLayer) {
    const data = {
      item_id: product.objectId,
      item_sku: product.sku,
      item_category: collection?.name,
      item_name: product.title,
      item_brand: "Tekla",
      url: window.location.href,
      image_url: product.thumbnail || product.thumbnail?.file?.url,
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Product Viewed (GTM)",
      ecommerce: { items: [data] },
    })
  }
}

export function trackSizeSelected(product, variant, collection) {
  if (window && window.dataLayer) {
    const data = {
      item_id: product.objectId,
      item_sku: product.sku,
      item_name: product.title,
      variant: variant?.sku,
      item_category: collection?.name,
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "select_item",
      ecommerce: { items: [data] },
    })
  }
}

export function trackProductClicked(product, collection) {
  if (window && window.dataLayer) {
    const data = {
      item_id: product.objectId,
      item_sku: product.sku,
      item_name: product.title,
      item_category: collection?.name,
      list: collection?.name,
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "select_item",
      ecommerce: { items: [data] },
    })
  }
}

export function trackRelatedProductClicked(product, collection) {
  if (window && window.dataLayer) {
    const data = {
      item_id: product.objectId,
      item_sku: product.sku,
      item_name: product.title,
      item_category: collection?.name,
      list: collection?.name,
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Related Product Clicked (GTM)",
      ecommerce: { items: [data] },
    })
  }
}

export function trackBeddingViewAllSizes(product, collection) {
  if (window && window.dataLayer) {
    const data = {
      item_id: product.objectId,
      item_sku: product.sku,
      item_name: product.title,
      item_category: collection.name,
      list: collection.name,
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Bedding View All Sizes Clicked (GTM)",
      ecommerce: { items: [data] },
    })
  }
}

export function trackRecommendedProduct({ productId, location }) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Recommended Product Clicked (GTM)",
      ecommerce: {
        item_id: productId,
        location: location,
      },
    })
  }
}

export function trackAddToCart(cart, product, variant, quantity, quickAddToCart = false) {
  const currencyCode = cart?.region?.currency_code
  const ma = variant.prices?.find(
    (ma) => ma.currency_code.toLowerCase() === currencyCode.toLowerCase()
  )
  const price = normalizeAmount(currencyCode, ma?.amount)

  if (window && window.dataLayer) {
    const data = {
      item_id: variant.product_id,
      item_sku: variant.sku,
      item_category: product.subcategory,
      item_name: product.title,
      item_brand: "Tekla",
      item_variant: variant?.sku,
      price,
      currency: currencyCode?.toUpperCase(),
      quantity,
      url: window.location.href,
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event:
        product.subcategory === "wishlist"
          ? "Product Added from Wishlist (GTM)"
          : "add_to_cart",
      email: cart.email,
      ecommerce: {
        value: price,
        currency: currencyCode?.toUpperCase(),
        items: [data],
      },
    })

    if (quickAddToCart) {
      window.dataLayer.push({
        event: "Product Added - Quick Add to Cart (GTM)",
        email: cart.email,
        ecommerce: {
          value: price,
          currency: currencyCode?.toUpperCase(),
          items: [data],
        },
      })
    }
  }
}

export function trackBundleAddToCart(cart, bundle, items) {
  const currencyCode = cart?.region?.currency_code
  const amount = items.reduce((acc, item) => {
    const price = item.variant.prices.find(
      (price) =>
        price.currency_code.toLowerCase() === currencyCode.toLowerCase()
    )
    return (acc += price.amount)
  }, 0)
  const price = normalizeAmount(currencyCode, amount)

  if (window && window.dataLayer) {
    const data = {
      value: price,
      currency: currencyCode?.toUpperCase(),
      items: items.map((item) => ({
        item_id: bundle.id,
        item_sku: bundle.sku,
        item_name: bundle.title,
        item_brand: "Tekla",
        item_variant: item.variantId,
        price,
        currency: currencyCode?.toUpperCase(),
        quantity: item.quantity,
        url: window.location.href,
      })),
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Bundle Added (GTM)",
      email: cart.email,
      ecommerce: data,
    })
  }
}

export function trackCouponEntered(cart, couponCode) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Coupon Entered (GTM)",
      ecommerce: {
        cart_id: cart?.id,
        coupon_id: couponCode,
      },
    })
  }
}

export function trackCouponApplied(cart, couponCode) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Coupon Applied (GTM)",
      ecommerce: {
        cart_id: cart?.id,
        discount: cart.discount,
        coupon_id: couponCode,
      },
    })
  }
}

export function trackCouponRemoved(cart) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Coupon Removed (GTM)",
      ecommerce: {
        cart_id: cart?.id,
        discount: cart.discount,
        coupon_id: cart.couponCode,
      },
    })
  }
}

export function trackRemoveFromCart(
  cart,
  product,
  variant,
  quantity,
  unit_price
) {
  const currencyCode = cart?.region?.currency_code
  const price = normalizeAmount(currencyCode, unit_price)

  if (window && window.dataLayer) {
    const data = {
      item_id: product.id,
      item_sku: product.sku,
      item_name: product.title,
      item_brand: "Tekla",
      item_variant: variant?.sku,
      price,
      currency: currencyCode?.toUpperCase(),
      quantity,
      image_url: product.thumbnail,
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Product Removed (GTM)",
      email: cart.email,
      ecommerce: {
        value: price,
        currency: currencyCode?.toUpperCase(),
        items: [data],
      },
    })
  }
}

export function trackBundleRemoveFromCart(cart, bundle) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Bundle Removed (GTM)",
      ecommerce: {
        cart_id: cart?.id,
        bundle_id: bundle.metadata.product_bundle_id,
        unique_id: bundle.metadata.unique_id,
        bundle_item_name: bundle.metadata.bundle_title,
        variants: bundle.ids,
      },
    })
  }
}

export function trackPurchase(order) {
  const currency = order.currency_code
  if (window && window.dataLayer) {
    let coupon
    if (order.discounts && order.discounts.length) {
      coupon = order.discounts[0].code
    }

    const data = {
      email: order.email,
      region_id: order.region_id,
      subtotal: normalizeAmount(currency, order.subtotal),
      value: normalizeAmount(
        currency,
        order.total - order.tax_total - order.shipping_total
      ),
      shipping: normalizeAmount(currency, order.shipping_total),
      tax: normalizeAmount(currency, order.tax_total),
      discount: normalizeAmount(currency, order.discount_total),
      coupon,
      transaction_id: order.display_id,
      currency: order?.currency_code?.toUpperCase(),
      items: order.items.map(
        (i) => {
          const currency = order.currency_code?.toUpperCase()
          const price = normalizeAmount(currency, i.unit_price)
          const tax = normalizeAmount(currency, i.tax_total)
          const discount = normalizeAmount(currency, i.discount_total)

          const skuParts = i.variant.sku?.split("-") || []
          skuParts.pop()

          return {
            item_id: i.variant.product_id,
            item_category: i.category,
            item_sku: skuParts.join("-"),
            item_name: i.title,
            item_brand: "TEKLA",
            item_variant: i.variant.sku || "",
            metadata: i.metadata,
            price,
            currency,
            quantity: i.quantity,
            tax,
            discount
          }
        },
        {
          // integrations: handleIntegrations(),
        }
      ),
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Purchase (GTM)",
      email: order.email,
      ecommerce: data,
    })
  }

  //if (typeof window !== "undefined") {
  //  if (window.fbq) {
  //    // Register FB Purchase
  //    window.fbq("track", "Purchase", {
  //      currency: currency,
  //      content_type: "product",
  //      value: normalizeAmount(currency, order.subtotal),
  //      contents,
  //    })
  //  }
  //}
}

export const trackMap = ({ order, location, accuracy, didAppear }) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Map Displayed (GTM)",
      ecommerce: {
        order: order,
        location: location,
        accuracy: accuracy,
        didAppear: didAppear,
      },
    })
  }
}

export function trackRestockSignup(cart, item, email) {
  if (window && window.dataLayer) {
    const skuParts = item.sku?.split("-") || []
    skuParts.pop()
    const data = {
      item_sku: skuParts.join("-"),
      item_name: item.title,
      variant: item.sku,
      variant_id: item.id,
      item_id: item.objectId,
      email: email,
      options: item.options,
      prices: item.prices,
      quantity: item.quantity,
      image_url: item.imgUrl,
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Restock Notification Signed Up (GTM)",
      ecommerce: { items: [data] },
    })
  }
}

export function trackAccountInformationChanged(customer) {
  if (window && window.analytics) {
    window.analytics.identify({
      firstName: customer.firstName,
      lastName: customer.lastName,
      birthday: customer.birthday,
    })
  }
}

export function trackRemoveFromWishlist(details) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Variant Removed From Wishlist (GTM)",
      ecommerce: details,
    })
  }
}

export function trackAddToWishlist({ cart, productTitle, variant, userData }) {
  const currencyCode = cart?.region?.currency_code
  const ma = variant.prices?.find(
    (ma) => ma.currency_code.toLowerCase() === currencyCode.toLowerCase()
  )
  const price = normalizeAmount(currencyCode, ma?.amount)

  if (window && window.dataLayer) {
    const data = {
      item_id: variant.product_id,
      item_variant: variant?.sku,
      item_name: productTitle,
      item_brand: "Tekla",
      price,
      currency: currencyCode?.toUpperCase(),
      url: window.location.href,
      user_id: userData.id,
      email: userData.email,
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Product Added to Wishlist (GTM)",
      email: cart.email,
      ecommerce: {
        value: price,
        currency: currencyCode?.toUpperCase(),
        items: [data],
      },
    })
  }
}

export function trackUserSignedUp({ id, first_name, last_name, email } = {}) {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "User Signed Up",
      ecommerce: {
        firstName: first_name,
        lastName: last_name,
        email: email,
        id: id,
      },
    })
  }
}

export function trackUserSignedIn({ id, first_name, last_name, email } = {}) {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "User Signed In (GTM)",
      ecommerce: {
        firstName: first_name,
        lastName: last_name,
        email: email,
        id: id,
      },
    })
  }
}

export function trackBundleAddToWishlist({ cart, bundle, items, userData }) {
  const currencyCode = cart?.region?.currency_code
  const amount = items.reduce((acc, item) => {
    const price = item.variant.prices.find(
      (price) =>
        price.currency_code.toLowerCase() === currencyCode.toLowerCase()
    )
    return (acc += price.amount)
  }, 0)

  const price = normalizeAmount(currencyCode, amount)

  if (window && window.dataLayer) {
    const data = {
      cart_id: cart?.id,
      bundle_id: bundle.id,
      variants: items,
      item_name: bundle.title,
      item_brand: "Tekla",
      price,
      currency: currencyCode?.toUpperCase(),
      url: window.location.href,
      user_id: userData.id,
      email: userData.email,
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Bundle Added to Wishlist (GTM)",
      ecommerce: { items: [data] },
    })
  }
}

export function trackShareWishlist(firstName, lastName, email, id) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Wishlist share link clicked (GTM)",
      ecommerce: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        id: id,
      },
    })
  }
}

export function trackShareWishlistCopyButtonClicked({
  firstName,
  lastName,
  email,
  id,
}) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Wishlist share button clicked (GTM)",
      ecommerce: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        id: id,
      },
    })
  }
}

export function trackSearchTriggered({ query }) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Products Searched (GTM)",
      ecommerce: {
        query: query,
        location: window.location.href,
      },
    })
  }
}
export function trackSearchMenuClicked() {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Search Menu Clicked (GTM)",
      ecommerce: {
        location: window.location.href,
      },
    })
  }
}
export function trackSearchProductClicked({ query, product }) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Search Product Clicked (GTM)",
      ecommerce: {
        query: query,
        location: window.location.href,
        product: product,
      },
    })
  }
}
export function trackBannerClicked(data) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Banner Clicked (GTM)",
      ecommerce: data,
    })
  }
}

export function subscribedToNewsletter(data) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Subscribed to newsletter (GTM)",
      email: data.email,
      ecommerce: data,
    })
  }
}

export function trackNavigationItemClicked(item) {
  if (window && window.dataLayer) {
    const data = {
      name: item.name,
      url: item.url,
      menuType: item.menuType,
    }

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Navigation Item Clicked (GTM)",
      ecommerce: data,
    })
  }
}

export function trackNewsletterSubscription(payload) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Newsletter checked with order placed (GTM)",
      email: payload.email,
      ecommerce: payload,
    })
  }
}

export function trackEmailAdded(email, cart) {
  if (window && window.dataLayer) {
    const currency = cart?.region?.currency_code
    const data = {
      value: normalizeAmount(
        currency,
        cart.total - cart.tax_total || 0 - cart.shipping_total || 0
      ),
      discount: normalizeAmount(currency, cart.discountTotal),
      tax: normalizeAmount(currency, cart.tax_total),
      coupon: cart.couponCode,
      currency: currency?.toUpperCase(),
      order_id: cart?.id,
      display_total: formatCartTotal(cart),
      items: cart?.items.map((item) => {
        const skuParts = item.variant.sku?.split("-") || []
        skuParts.pop()

        const price = normalizeAmount(currency, item.unit_price)

        let imgUrl = item.thumbnail || ""
        if (imgUrl.startsWith("//")) {
          imgUrl = `https:${imgUrl}`
        }

        return {
          item_id: item.variant.product_id,
          item_sku: skuParts.join("-"),
          item_name: item.title,
          item_variant: item.variant.sku,
          display_price: formatMoneyAmount(
            {
              currencyCode: cart?.region?.currency_code,
              amount: item.original_total,
            },
            0
          ),
          item_color: item.description,
          price,
          quantity: item.quantity,
          image_url: imgUrl,
        }
      }),
    }

    window.dataLayer.push({
      event: "Email field added (GTM)",
      email,
      ecommerce: data,
    })
  }
}

export function trackCartShared(cartId) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Cart shared (GTM)",
      ecommerce: {
        cart_id: cartId,
      },
    })
  }
}

export function trackExpressCheckout(cartId, payment) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Express Checkout (GTM)",
      ecommerce: {
        cart_id: cartId,
        payment: payment,
      },
    })
  }
}

export function trackExpressCheckoutClick(cartId) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Express Checkout Button Click (GTM)",
      ecommerce: {
        cart_id: cartId,
      },
    })
  }
}

export function trackFilterProducts(filterData) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "Filter Products (GTM)",
      ecommerce: filterData,
    })
  }
}
